import Cookies from "js-cookie";
import config from "../../config";

const cookies = config.cookies;

export function clearCookies() {
  Cookies.remove(cookies.name,{domain:cookies.domain});
}
export function setCookies(data) {
  try {

    let cookie = {}
    if(data.token)
      cookie.token = data.token;
      
    if (data.user && data.user.email)
      cookie.email = data.user.email;

    if (data.user && data.user.id)
      cookie.id = data.user.id

    if (data.user && data.user.first_name)
      cookie.first_name = data.user.first_name;

    if (data.user && data.user.last_name)
      cookie.last_name = data.user.last_name;

    //if (data && data.user && data.user.cuk_media)
      cookie.cuk_media = data.user && data.user.cuk_media ? data.user.cuk_media : {} ;

    if (data.user && data.user.contact_phone)
      cookie.contact_phone = data.user.contact_phone;  
    
    if (data.user && data.user.alternate_phone)
      cookie.alternate_phone = data.user.alternate_phone;    

    if (data.user && data.user.created_date)
      cookie.created_date = data.user.created_date;    
    
    if (data.user && data.user.address)
      cookie.address = data.user.address;     
    
    if (data.user && data.user.zipcode)
      cookie.zipcode = data.user.zipcode;   
    
    if (data.user.role_id)
      cookie.role_id = data.user.role_id;

    if (data.user &&  data.user.source )
      cookie.source = data.user.source;  

    if (data.user &&  data.user.singup_code )
      cookie.singup_code = data.user.singup_code; 

    if (data.user &&  data.user.service_id )
      cookie.service_id = data.user.service_id; 

    if (data.user &&  data.user.service_key )
      cookie.service_key = data.user.service_key; 

    Cookies.set(cookies.name,cookie,{ expires: cookies.expiry,domain: cookies.domain});
  } catch (e) {
    console.log('set cookie err',e)
  }
}
export function updateCookies(data) {

  let currentToken = Cookies.getJSON(cookies.name);

  if (data && data.email)
    currentToken.email = data.email;

  if (data && data.first_name)
    currentToken.first_name = data.first_name;

  if (data && data.last_name)
    currentToken.last_name = data.last_name;

  //if (data && data.cuk_media)
    currentToken.cuk_media = data.cuk_media ? data.cuk_media : {};
  
  Cookies.set(cookies.name, currentToken,{ expires: cookies.expiry,domain: cookies.domain});
}

export function getCookies() {
  return Cookies.get(cookies.name)
}
export function getJsonCookies() {
  let data = Cookies.getJSON(cookies.name);
  if(data)
    return data.token
}
export function getCookie() {
  let token = Cookies.getJSON(cookies.name);
  if (token)
    return token
}

export function getCookieByValue(data) {
  return Cookies.get(data)
}

export function setAllCookie(variable, data) {
  try{
  Cookies.set(variable, data,{ expires: cookies.expiry,domain: cookies.domain});
  } catch (e) {
    console.log('set LangCookie cookie err',e)
  }
}

export function getAllCookies(variable) {
  return Cookies.get(variable)
}

export function setCircleCookie(data) {
  try{
  Cookies.set(cookies.circle, {id: data.id, circle_slug: data.circle_slug, show_unassigned_widget: data.show_unassigned_widget},{ expires: cookies.expiry,domain: cookies.domain});
  //Cookies.set('jointly-circle', data, { expires: 7 });

  } catch (e) {
    console.log('set circle cookie err',e)
  }
}
export function getCircleCookie() {
  return Cookies.getJSON(cookies.circle);
}
export function removeCircleCookie() {
  Cookies.remove(cookies.circle,{domain:cookies.domain});
}


export function setUserAttempt(user, type) {

  //type - login,signup,forget_password

  let data = Cookies.getJSON("jointlyVisitor") ? Cookies.getJSON("jointlyVisitor") : {};

  let initial = {
    visitorKey: Math.random().toString().substring(2, 15),
    createdTime: new Date(),
    updatedTime: null,
    visitedCount: 1
  }

 

  if (!data[type]) {
    switch (type) {
      case 'login':
        data = { ...data, login: { ...initial } }
        break;
      case 'signup':
        data = { ...data, signup: { ...initial } }
        break;
      case 'forget_password':
        data = { ...data, forget_password: { ...initial } }
        break;

      default:
        break;
    }
  } else if (Object.keys(data).length) {
    switch (type) {
      case 'login':
        data = { ...data, login: { ...data.login, ...updateTimeAndCount(data[type].visitedCount) } }
        break;
      case 'signup':
        data = { ...data, signup: { ...data.signup, ...updateTimeAndCount(data[type].visitedCount) } }
        break;
      case 'forget_password':
        data = { ...data, forget_password: { ...data.forget_password, ...updateTimeAndCount(data[type].visitedCount) } }
        break;

      default:
        break;
    }
  }
  var inOneHour = 2/48
  Cookies.set("jointlyVisitor", JSON.stringify(data), { expires: inOneHour });
}

export async function getVisitedCount(type) {
  //type - login,signup,forget_password
  let data = Cookies.getJSON("jointlyVisitor") ? Cookies.getJSON("jointlyVisitor") : null;
  var inOneHour = 2/48

  if(data){
    switch (type) {
      case 'login':
        if (data && data.login && data.login.updatedTime) {
          let diff = getTimeDifferenceInHours(data.login.updatedTime)
          if (Math.abs(Math.round(diff)) > 0) {
            data = {...data,login:{}}
            Cookies.set("jointlyVisitor", JSON.stringify(data), { expires: inOneHour });
          }
        }
        break;
        case 'signup':
          if (data && data.signup && data.signup.updatedTime) {
            let diff = await getTimeDifferenceInHours(data.signup.updatedTime)
            if (Math.abs(Math.round(diff)) > 0) {
              data = {...data,signup:{}}
              Cookies.set("jointlyVisitor", JSON.stringify(data), { expires: inOneHour });
            }
          }
          break;
          case 'forget_password':
            if (data && data.forget_password && data.forget_password.updatedTime) {
              let diff = getTimeDifferenceInHours(data.forget_password.updatedTime)
              if (Math.abs(Math.round(diff)) > 0) {
                data = {...data,forget_password:{}}
                Cookies.set("jointlyVisitor", JSON.stringify(data), { expires: inOneHour });
              }
            }
            break;

      default:
        break;
    }
  }
  return data && data[type] &&  data[type].visitedCount ? data[type].visitedCount : 0
}

function updateTimeAndCount(count) {
  return {
    updatedTime:new Date(),
    visitedCount: count + 1
  }
}
function getTimeDifferenceInHours(time) {
  let diff = (new Date().getTime() - new Date(time).getTime()) / 1000;
  diff /= (60 * 60);
  return diff
}

export function validatePassword(value) {
  if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*\-+{}[\]|']).{8,}$/.test(value)) {
    return true
  } else {
    return false
  }
}
export function range(start, stop, step) {
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == 'undefined') {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  var result = [];
  for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
};

export const primaryColor = '#e20612';

export function getInitialValues (key, data) {
  if (!key) return "";
  return data.siteSettings?.rows?.find(item => item.option_key === key)?.option_value || "";
}


import { api, catchHandler } from "../../helpers/axios";
import config from "../../../config";
import { objToUrlParams, generateRandomKey } from "../../helpers";

export function getDigests(data){
    return api()
        .get(`${config.routes.digests}?${objToUrlParams(data)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function saveDigests(data){
    data.csrf = generateRandomKey();
    return api()
        .post(config.routes.userDigests, data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function toggleMemberStatus(data){
    return api()
        .put(config.routes.getMember, data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function deleteMember(data){
    return api()
        .delete(config.routes.getMember, {data:data})
        .then(res => res.data)
        .catch(catchHandler)
}
export function listAllPermissions(data){
    return api()
        .get(`${config.routes.listMainpermissions}?${objToUrlParams(data)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function togglePermissions(data){
    data.csrf = generateRandomKey();
    return api()
        .post(config.routes.userPermission, data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function toggleCircleAdminPermission(data) {
    return api()
        .put(config.routes.circleAdminPermission, data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getSiteSettings(data){
    return api()
        .get(`${config.routes.siteSettings}?${objToUrlParams(data)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function updateSiteSettings(data){
    return api()
        .put(config.routes.siteSettings,data)
        .then(res => res.data)
        .catch(catchHandler)
}
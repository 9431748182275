import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import TopBar from "../../containers/top-bar";
import ScrollToTop from "react-scroll-up";
import { Button } from 'antd';
import PaymentModal from '../paymentModal';
import AcessCodeModal from '../acessCodeModal';
// import { history } from "../../store";
import moment from 'moment';
import { Elements, StripeProvider } from 'react-stripe-elements'
import config from '../../../config'
import { generateLabels } from "../../helpers/index";
import { getInitialValues } from "../../helpers/utility";

const mapDispatchToProps = ({ circles, user, settings }) => {
  return {
    ...circles,
    ...user,
    ...settings
  };
};

const mapStateToProps = ({ circles, user, settings }) => {
  return {
    ...circles,
    ...user,
    ...settings
  };
};

class BaseLayout extends Component {
  componentDidMount() {
      this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loaded && this.props.loaded) {
      this.init();
    }
  }


  state = {
    payModalVisible: false,
    circle_validity: 0,
    circle_created_date: new Date()
  }

  async init() {
    await this.props.me();
    await this.props.listCircles()
    this.props.getSubscription({ id: 1 });
    await this.props.getSiteSettings({nopaging: 1});
    
    const circle_validity = await getInitialValues('demo_circle_validity', this.props);
    this.setState({ circle_validity: Number(circle_validity) })
    if (this.props.circle && this.props.circle.id) {
      this.setState({ circle_created_date: this.props.circle.created_date })
      await this.props.getNotificationCount({ circle_id: this.props.circle.id })
      await this.props.getInvities({ circle_id: this.props.circle.id,nopaging: 1, status: 'pending', invitation_type: 'circle' })
    }
  }
  async showPaymentModal() {
    this.setState({ payModalVisible: true })
  }
  handleClose() {
    this.setState({ payModalVisible: false })
  }

  async onPaymentSuccess() {
    await this.props.getCircle({ circle_slug: this.props.circle.circle_slug });
    this.props.getCirclePermissions({ circle_id: this.props.circle.id });
  }
 
  async showFreeAccessModal() {
    try {
      const result = await this.acessCodeModal.open();
      if (result && result.status) {
        this.showPaymentModal();
      } else {
        result.circle_id = this.props.circle.id
        // result.email = null
        await this.props.promoCode(result);
        // await this.props.useFreeAccessCode(result);
      }
    } catch (e) {
      console.error(e);
    }
  }

  errHandling = async () => {
    // this.setState({ title: "Invalid Card", content: "Re-enter card details" })
    // const confirm = await this.retryConfirmModal.open();
    // if(confirm === 2)
    //   this.showPaymentModal();
    // else 
    // history.push(`/circle/${this.props.circle.circle_slug}`)
  }

  getMessage() {
    let daydiff = moment().diff(this.state.circle_created_date, 'days');
    let diff = this.state.circle_validity - daydiff;
    let msg = generateLabels("FOOTER_ONE").replace("#FIELD#", Math.abs(diff))
    return msg
  }
  render() {
    const { children, settings } = this.props;
    return (
      <div className={`app-body ${settings.calendarPage ? 'calendar-page': ''}`}>
        {settings.topbar ? <TopBar page={settings.title} hideSearch={settings.hideSearch} /> : null}
        <div className={`app-body ${this.props.circle && this.props.circle.subscription_type && (this.props.circle.subscription_type !== 'paid' && this.props.circle.subscription_type !== 'code') ? "subscription-bar-enabled" : ""}`}>
          {children}
        </div>
        <ScrollToTop showUnder={160} style={{ bottom: 70, zIndex: '1052' }}>
          <span className="scroll-top-button icon-arrow-up"></span>
        </ScrollToTop>
        {window.location.pathname !== "/dashboard" && this.props.circle && this.props.circle.subscription_type && (this.props.circle.subscription_type !== 'paid' && this.props.circle.subscription_type !== 'code') && (
          <Fragment>
            {this.props.circle && this.props.circle.id ? (
              <Fragment>
                <StripeProvider apiKey={config.stripeKey}>
                  <Elements>
                    <PaymentModal errHandling={this.errHandling} circle_id={this.props.circle.id} onPaymentSuccess={this.onPaymentSuccess.bind(this)} closeModal={this.handleClose.bind(this)} showFreeAccessModal={this.showFreeAccessModal.bind(this)} visible={this.state.payModalVisible} ref={ref => (this.paymentModal = ref)} subscription={this.props.subscription} />
                  </Elements>
                </StripeProvider>
                <AcessCodeModal ref={ref => (this.acessCodeModal = ref)} user={this.props.user} />
                <div className={`${(settings.title === "Circle calendar" || settings.title === "Circle events") ? "subscription-has-expired header-cta-active" : "subscription-has-expired"}`}>
                  <span className="text-block">{this.props.circle.status === 'active' ? this.getMessage() : generateLabels("OTHERS_CIR_TRIAL")} </span>
                  <Button className="btn-block" type="primary" onClick={this.showPaymentModal.bind(this)}>{generateLabels("FOOTER_BUTTON_UPGRADE_NOW")}</Button>
                </div>
              </Fragment>
            ) : null}
          </Fragment>)
        }
      </div>
    )
  }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(BaseLayout);

